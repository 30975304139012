import Layout from '@/views/Layout';

export default {
    name: 'stock',
    component: Layout,
    meta: { name: '资料列表' },
    children: [
        {
            name: 'study',
            component: () => import('@/views/Stock/study'),
            meta: { name: '学院' },
        },
        {
            name: 'study/:id',
            component: () => import('@/views/Stock/studyDetail'),
            meta: { name: '学习详情' },
        },
        {
            name: 'article',
            component: () => import('@/views/Stock/article'),
            meta: { name: '文章' },
        },
        {
            name: 'psd',
            component: () => import('@/views/Stock/psd'),
            meta: { name: '素材' },
        },
        {
            name: 'file',
            component: () => import('@/views/Stock/file'),
            meta: { name: '模板' },
        },
    ],
};
