import Layout from '@/views/Layout';

export default {
    name: 'article',
    component: Layout,
    meta: { name: '热文资讯' },
    redirect: '/article/hot',
    children: [
        {
            name: 'hot',
            component: () => import('@/views/Article/hot'),
            meta: { name: '热文资讯' },
        },
        {
            name: 'custom',
            component: () => import('@/views/Article/custom'),
            meta: { name: '自选文章' },
        },
        {
            name: 'detail',
            component: () => import('@/views/Article/detail'),
            meta: { name: '编辑详情' },
        },
    ],
};
