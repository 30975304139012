import Layout from '@/views/Layout';

export default {
    name: 'news',
    component: Layout,
    meta: { name: '新闻资讯' },
    redirect: '/news/morning',
    children: [
        {
            name: 'morning/:agentId',
            component: () => import('@/views/News/morning'),
            meta: { name: '今日早报' },
        },
        {
            name: 'evening',
            component: () => import('@/views/News/evening'),
            meta: { name: '今日早报' },
        },
    ],
};
