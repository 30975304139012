import { createApp } from 'vue';
import App from './App.vue';
import utils from './utils';
const app = createApp(App);
Set.prototype.push = function (...e) {
    for (const a of e) this.add(a);
    return this;
};
utils(app);
// 屏蔽错误信息
// app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;
app.mount('#app');
