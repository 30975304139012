<template>
    <section v-if="modelValue === 1" class="one flex flex-sb">
        <van-image class="one-avatar" :src="avatar" fit="contain" round />
        <div class="flex-1 pl-2">
            <div class="flex">
                <span class="f-lg">{{ name }}</span>
                <van-tag color="#aaf" text-color="blue" class="mx-1">{{ job }}</van-tag>
                <span class="f-sm" v-color:blue>{{ mobile }}</span>
            </div>
            <div class="flex">
                <i class="icon icon-quote"></i>
                <span class="f-sm pl-1" v-color:blue>{{ sign }}</span>
            </div>
        </div>
    </section>
    <section v-else-if="modelValue === 2" class="two ba-sm p-4">
        <div class="left">
            <div class="flex">
                <van-image class="two-logo" radius="4" :src="logo" />
                <span class="f-bs pl-2">{{ company }}</span>
            </div>
            <p class="flex flex-b py-4">
                <span class="f-lg">{{ name }}</span>
                <span class="px-1 c-gray">/</span>
                <span class="f-sm c-gray">{{ job }}</span>
            </p>
            <p class="f-sm">T&ensp;/&ensp;{{ mobile }}</p>
            <p class="f-sm">A&ensp;/&ensp;{{ city }}</p>
            <p class="f-sm">P&ensp;/&ensp;{{ sign }}</p>
        </div>
        <div class="right">
            <van-image class="two-img" :src="avatar" round />
            <van-image class="two-img" :src="weixinCode" />
        </div>
    </section>
    <section class="three ba-bs p-4 bg-light flex" v-else-if="modelValue === 3">
        <van-image class="three-avatar" :src="avatar" radius="4" />
        <div class="flex flex-col flex-s pl-2 c-gray f-sm flex-1">
            <span class="flex">
                <span>{{ name }}</span>
                <i class="icon icon-diamond pl-1 f-sm"></i>
            </span>
            <span>{{ job }}</span>
            <span>{{ address }}{{ shop }}</span>
        </div>
        <van-button size="small" plain type="primary" class="px-2" round @click="$call(mobile)">电话咨询</van-button>
        <van-button size="small" type="primary" class="px-2 ml-2" round @click="$router.push('/mine/chat/' + openid)">在线咨询</van-button>
    </section>
    <section v-else-if="modelValue === 4">4</section>
    <section v-else-if="modelValue === 5">5</section>
    <!-- 圈子名片列表 -->
    <section class="py-4 pl-4 bg-light f-bs" v-else-if="modelValue === 7" @click.stop="$router.push('/sns/card/' + id)">
        <div class="flex">
            <van-image class="img-bs" :src="avatar" />
            <div class="pl-2 flex-1">
                <div class="flex flex-sb mb-1">
                    <span class="f-lg ovh" style="max-width: 7em; white-space: nowrap; text-overflow: ellipsis">{{ name }}</span>
                    <span class="pl-1 c-gray flex-1">{{ job }}</span>
                    <span @click.stop="$router.push('/sns/site')">
                        <van-icon name="browsing-history" />
                        <span class="px-1" @click.stop="$router.push('/sns/site/' + id)">主页</span>
                    </span>
                    <span>
                        <van-icon name="share" />
                        <span class="px-1">分享</span>
                    </span>
                </div>
                <span class="c-gray">{{ company }}</span>
            </div>
        </div>
        <van-divider class="my-2" />
        <div class="flex flex-sb f-sm">
            <span class="cBlue ml-4">人脉：100</span>
            <span class="cBlue ml-4">热度：100</span>
            <span class="cBlue ml-4">信用：A</span>
            <van-tag class="ml-4 pl-2 pr-4 right--2" type="primary" round>{{ city || '保密' }}</van-tag>
        </div>
    </section>
    <!-- 圈子名片详情 -->
    <section class="py-4 pl-4 bg-light f-bs pos-r" v-else-if="modelValue === 9" @click.stop="$router.push('/sns/card/' + id)">
        <div class="flex">
            <van-image class="img-lg" round :src="avatar" />
            <div class="pl-2 flex-1">
                <div class="flex flex-sb flex-b mb-1">
                    <span class="f-lg ovh">{{ name }}</span>
                    <span class="pl-1 f-sm c-gray flex-1">{{ job }}</span>
                </div>
                <span class="c-gray">{{ company }}</span>
            </div>
        </div>
        <van-divider class="my-2" />
        <div class="f-bs pos-r">
            <p class="my-1">
                <span class="s-2 c-gray">手机号:</span>
                <span class="f-mx" v-color:blue>{{ mobile }}</span>
                <small class="c-gray ml-4">(交换名片后显示)</small>
            </p>
            <p class="my-1">
                <span class="s-2 c-gray">常驻城市:</span>
                <span>{{ city }}</span>
            </p>
            <p class="my-1">
                <span class="s-2 c-gray">微信号:</span>
                <span>{{ weixin }}</span>
            </p>
            <p class="my-1">
                <span class="s-2 c-gray">电子邮箱:</span>
                <span>{{ mail }}</span>
            </p>
            <van-image class="img-lg ba-sm pos-a bottom-0 right-1" :src="pageCode" fit="contain" alt="当前页面二维码" />
        </div>
        <div class="pos-a right-2 top-2">
            <van-popover v-model:show="showPopover" theme="dark" :actions="actions" placement="bottom-end" @select="lookUser">
                <template #reference>
                    <van-icon name="weapp-nav" class="pr-5" />
                </template>
            </van-popover>
        </div>
    </section>
    <section v-else-if="modelValue === 8" class="sty8 p-4">
        <div class="left">
            <div class="flex">
                <van-image class="sty8-logo" radius="4" :src="logo" />
                <span class="f-bs pl-2">{{ company }}</span>
            </div>
            <p class="flex flex-b py-4">
                <span class="f-lg">{{ name }}</span>
                <span class="px-1 c-gray-1">/</span>
                <span class="f-sm c-gray-1">{{ job }}</span>
            </p>
            <p class="f-sm">T&ensp;/&ensp;{{ mobile }}</p>
            <p class="f-sm">A&ensp;/&ensp;{{ city }}</p>
            <p class="f-sm">P&ensp;/&ensp;{{ sign }}</p>
        </div>
        <div class="right">
            <van-image class="sty8-img" :src="avatar" round />
            <van-image class="sty8-img" :src="weixinCode" />
        </div>
    </section>
    <section v-else class="flex">
        <van-image class="def-wx ba-sm" :src="weixinCode" fit="contain" alt="微信二维码" />
        <div class="e-card-info pl-2">
            <p class="flex flex-b mb-1">
                <span class="f-lg">{{ name }}</span>
                <span class="f-bs px-1 c-gray">/</span>
                <span class="f-bs c-gray">{{ job }}</span>
            </p>
            <p class="f-bs c-gray">T&ensp;/&ensp;{{ mobile }}</p>
            <p class="f-bs c-gray">A&ensp;/&ensp;{{ city }}{{ shop }}</p>
            <p class="f-bs c-gray">P&ensp;/&ensp;{{ sign }}</p>
        </div>
    </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import QRCode from 'qrcode';
const prop = defineProps({
    id: String,
    mobile: String,
    weixin: String,
    weixinCode: String,
    name: String,
    avatar: String,
    job: String,
    company: String,
    city: String,
    mail: String,
    shop: String,
    good: String,
    logo: String,
    openid: String,
    address: String,
    sign: String,
    modelValue: Number,
    edit: Boolean,
    withMe: Object,
});
const showPopover = ref(false);
const actions = computed(function () {
    return [{ text: '交换名片' }, { text: prop.withMe?.look ? '取消关注' : '关注我' }, { text: '申请看主页' }];
});
async function lookUser({ text }) {
    let type;
    if (text.match('名片')) type = 'card';
    if (text.match('主页')) type = 'home';
    if (text.match('关注')) type = 'look';
    const { msg } = await http.sns('lookUser', { user: prop.id, type });
    app.$ok(msg);
    location.reload();
}
const pageCode = ref('');
QRCode.toDataURL(location.href, function (err, url) {
    pageCode.value = url;
});
</script>

<style lang="scss" scoped>
.def-wx {
    width: $img-mx;
    height: $img-mx;
}
.turn {
    right: 0;
}
.one {
    &-avatar {
        height: $img-bs;
        width: $img-bs;
    }
}
.two {
    display: grid;
    grid-template-columns: 1fr $img-lg;
    box-shadow: 0 0 $spacing-lg $bg-grey;
    border-bottom: $spacing-sm solid blue;
    &-logo {
        width: $img-sm;
        height: $img-sm;
    }
    &-img {
        width: $img-lg;
        height: $img-lg;
    }
    .left {
    }
    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.three {
    box-shadow: 0 $spacing-sm $spacing-bs $bg-grey;

    &-avatar {
        width: $img-bs;
        height: $img-bs;
    }
}
.sty8 {
    display: grid;
    grid-template-columns: 1fr $img-lg;
    &-logo {
        width: $img-sm;
        height: $img-sm;
    }
    &-img {
        width: $img-lg;
        height: $img-lg;
    }
    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>
