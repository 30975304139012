import Layout from '@/views/Layout';

export default {
    alias: '/',
    name: 'mine',
    component: Layout,
    meta: { name: '个人中心' },
    redirect: '/mine/mine',
    children: [
        {
            name: 'mine',
            component: () => import('@/views/Mine'),
            meta: { name: '个人中心' },
        },
        {
            name: 'edit',
            component: () => import('@/views/Mine/edit'),
            meta: { name: '完善名片' },
        },
        {
            name: 'customer',
            component: () => import('@/views/Mine/customer'),
            meta: { name: '来访客户' },
        },
        {
            name: 'card',
            component: () => import('@/views/Mine/card'),
            meta: { name: '专业名片' },
        },
        {
            name: 'avatar',
            component: () => import('@/views/Mine/avatar'),
            meta: { name: '个性头像' },
        },
        {
            name: 'chat',
            component: () => import('@/views/Mine/chat'),
            meta: { name: '消息中心' },
        },
        {
            name: 'chat/:uuid',
            component: () => import('@/views/Mine/chat/chat'),
            meta: { name: '消息详情' },
        },
    ],
};
