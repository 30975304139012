<template>
    <span>{{ score }}</span>
</template>

<script>
export default {
    name: 'num-ani',
    props: { num: Number },
    data() {
        return {
            score: 0,
        };
    },
    watch: {
        num: {
            handler(v = 1, o = 0) {
                let n = parseInt((v - o) / 20);
                if (n < 20) n += 1;
                const timeId = setInterval(() => {
                    if (this.score < v) {
                        this.score += n;
                    } else {
                        this.score = v;
                        clearInterval(timeId);
                    }
                }, 50);
            },
            immediate: true,
        },
    },
};
</script>
