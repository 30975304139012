<template>
    <iframe class="locPicker" :src="park"></iframe>
</template>

<script>
export default {
    name: 'loc-picker',
    props: {
        readonly: Boolean,
        loc: Object,
    },
    computed: {
        park() {
            const loc = this.loc ?? {};
            const url = 'https://apis.map.qq.com/tools';
            const key = 'key=MRDBZ-IXQK5-5EYIA-QC7PY-K2QEK-2LFID&referer=myapp';
            if (this.readonly) {
                return `${url}/poimarker?type=0&marker=coord:${loc.latlng.lat},${loc.latlng.lng};title:${loc.poiname};addr:${loc.poiaddress}&${key}`;
            } else {
                return `${url}/locpicker?type=1&${key}`;
            }
        },
    },
    methods: {
        change(event) {
            const loc = event.data;
            if (loc && loc.module === 'locationPicker') {
                this.$emit('confirm', loc);
            }
        },
    },
    mounted() {
        window.addEventListener('message', this.change, false);
    },
    unmounted() {
        window.removeEventListener('message', this.change, false);
    },
};
</script>

<style lang="scss" scoped>
.locPicker {
    position: fixed;
    z-index: 1000;
    height: 100%;
    width: 100%;
    border: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
