import Poster from '@/views/Poster';

export default {
    name: 'poster',
    component: Poster,
    meta: { name: '海报模板' },
    redirect: '/poster/popular',
    children: [
        {
            name: 'popular',
            component: () => import('@/views/Poster/popular'),
            meta: { name: '精选海报' },
        },
        {
            name: 'custom',
            component: () => import('@/views/Poster/custom'),
            meta: { name: '自选海报' },
        },
    ],
};
