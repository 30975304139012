import { createRouter, createWebHashHistory } from 'vue-router';
import article from './article';
import mine from './mine';
import news from './news';
import poster from './poster';
import sns from './sns';
import stock from './stock';
const routes = [article, mine, news, poster, sns, stock];
for (const a of routes) {
    if (a.meta.nav !== false) a.meta.nav = true;
    if (!a.path) a.path = '/' + a.name;
    for (const b of a.children ?? []) {
        if (!b.path) b.path = b.name;
        if (!b.meta.nav) b.meta.nav = false;
        b.name = a.name + '.' + b.name;
    }
}
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.name;
    goTop();
    next();
});
export default router;
