/**
 * 网络请求封装
 * @type {Map<any, any>}
 */
const httpCache = new Map();
window.http = async (url = '', data = {}, method = 'POST') => {
    const key = JSON.stringify(url) + JSON.stringify(data);
    if (httpCache.has(key)) return httpCache.get(key);
    if (!/^\/sns/g.test(url)) url = 'https://api.ajl.cn/v2' + '/' + url;
    async function handler(will, key) {
        try {
            const jsonStr = await will;
            const data = await jsonStr.json();
            if (jsonStr.status === 200) {
                setTimeout(() => httpCache.delete(key), 600);
                if (url.match('api.ajl.cn') && data?.data?.pageSize) {
                    data.data.last = data.data.count <= data.data.page * data.data.pageSize;
                    data.data.page++;
                }
                if (data.list?.length === 0) data.data.last = true;
                return data;
            } else {
                throw data.msg;
            }
        } catch (e) {
            httpCache.delete(key);
            if (typeof e === 'string') app.$notify(e);
            return Promise.reject(typeof e === 'string' ? e : '请求失败');
        }
    }
    const body = JSON.stringify(data);
    const will = handler(
        fetch(url, {
            method,
            body,
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }),
        key
    );
    httpCache.set(key, will);
    return will;
};
http.dict = async function (code) {
    return await http('/sns/dict', { code });
};
http.sns = function (url, pyload) {
    return http('/sns/' + url, pyload);
};
http.upload = async function (files) {
    const { data } = await http.sns('fileToken');
    const up = (file) => {
        return new Promise((resolve) => {
            const name = hash() + file.name.substring(file.name.lastIndexOf('.'));
            const path = dayjs().format('YYYY年/MM月/DD日/') + name;
            const { accessid, policy, signature, host } = data;
            const body = new FormData();
            body.append('name', name);
            body.append('key', path);
            body.append('policy', policy);
            body.append('OSSAccessKeyId', accessid);
            body.append('signature', signature);
            body.append('file', file);
            fetch(host, { method: 'POST', body }).then(() => resolve(host + '/' + path));
        });
    };
    if (files instanceof File) {
        return up(files);
    } else {
        const will = files.map((file) => up(file));
        return Promise.all(will);
    }
};
