<template>
    <van-config-provider :theme-vars="themeVars">
        <!-- 顶部安全区 -->
        <div class="van-safe-area-top"></div>
        <van-nav-bar v-show="meta.nav" :title="meta.name" left-text="返回" left-arrow @click-left="back"></van-nav-bar>
        <div class="layout">
            <router-view />
            <div ref="last" class="layout-last">苏ICP备2020052756号 ©2022 Ajl Inc. All rights reserved.</div>
        </div>
        <!-- 底部安全区 -->
        <div class="van-safe-area-bottom"></div>
        <van-overlay class="center" style="z-index: 1000000" :show="loading">
            <van-loading />
        </van-overlay>
    </van-config-provider>
</template>
<script>
export default {
    data() {
        return {
            onePath: '',
            lRed: 'linear-gradient(20deg,red,orange)',
            lBlue: 'linear-gradient(20deg,blue,skyblue)',
            loading: false,
            themeVars: {
                rateIconFullColor: 'blue',
                sliderActiveBackgroundColor: 'blue',
                buttonPrimaryBorderColor: 'blue',
                buttonPrimaryBackgroundColor: 'blue',
                tabActiveTextColor: 'blue',
                tabsDefaultColor: 'blue',
                tabsBottomBarColor: 'blue',
                sidebarSelectedBorderColor: 'blue',
            },
        };
    },
    computed: {
        meta() {
            return this.$route.meta ?? {};
        },
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
    },

    mounted() {
        this.onePath = location.href;
        window.app = this;
        app.$ok = (msg) => app.$notify({ type: 'primary', message: msg });
        const obs = new IntersectionObserver(function (els) {
            if (els[0]['intersectionRatio']) {
                const ev = new CustomEvent('onBottom');
                document.dispatchEvent(ev);
            }
        });
        obs.observe(this.$refs.last);
    },
};
</script>
<style lang="scss">
body {
    overflow-x: hidden;
    height: 100%;
    background-color: #eee;
}
.nav {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $sm;
    &-btn {
        width: $bs * 4;
    }
    &-name {
        flex: 1;
        font-size: $bs;
        text-align: center;
    }
}
.layout {
    width: 375px;
    display: flex;
    flex-direction: column;
    position: relative;
    &-last {
        position: absolute;
        z-index: 999;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 8px;
        text-align: center;
        color: gray;
        background-color: rgba(black, 0.1);
        opacity: 0;
    }
}
</style>
