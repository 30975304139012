<template>
    <template v-if="type === 'date'">
        <van-field :required="required" v-if="readonly" :modelValue="modelValue" :label="label" :placeholder="'请选择' + label" readonly />
        <van-field
            :required="required"
            v-else
            :modelValue="modelValue"
            :label="label"
            :placeholder="'请选择' + label"
            is-link
            readonly
            @click="showCalendar = true"
        />
    </template>
    <template v-else-if="type === 'loc'">
        <van-field
            :required="required"
            :modelValue="modelValue?.poiaddress"
            :label="label"
            :placeholder="'请选择' + label"
            is-link
            readonly
            @click="showLoc = true"
        />
    </template>
    <template v-else-if="type === 'file'">
        <template v-if="multiple">
            <van-field :required="required" v-if="label" label-width="100%" :label="label" readonly :border="false" style="padding-bottom: 0" />
            <div class="bg-light p-1">
                <van-uploader v-if="readonly" :model-value="files" :show-upload="false" :deletable="false" />
                <van-uploader v-else v-model="fileList" multiple :max-count="maxCount" :beforeRead="beforeRead" :accept="accept" />
            </div>
        </template>
        <van-cell-group v-else>
            <van-field :required="required" :label="label" readonly>
                <template #button>
                    <van-uploader v-model="fileList" :max-count="1" :beforeRead="beforeRead" :accept="accept" :readonly="readonly" />
                </template>
            </van-field>
        </van-cell-group>
    </template>
    <template v-else-if="type === 'textarea'">
        <van-field :required="required" :label="label" label-width="100%" readonly :border="false" style="padding-bottom: 0" />
        <van-field
            :required="required"
            :modelValue="modelValue"
            @update:model-value="$emit('update:modelValue', $event)"
            type="textarea"
            style="padding-top: 0"
            :placeholder="placeholder"
            :maxlength="max"
            :rows="rows"
            show-word-limit
        />
    </template>
    <template v-else-if="type === 'img'">
        <van-cell-group>
            <van-field :required="required" :label="label" readonly>
                <template #button>
                    <van-image class="img-lg" :src="cov(modelValue)" radius="6" @click="chooseFile"></van-image>
                </template>
            </van-field>
        </van-cell-group>
    </template>
    <van-calendar show v-if="showCalendar" @confirm="onCalendar" />
    <loc-picker :loc="modelValue" v-if="showLoc" @confirm="onLoc" :readonly="readonly" />
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import LocPicker from '@/components/loc-picker';
const prop = defineProps({
    modelValue: [String, Number, Object, Array],
    label: String,
    type: String,
    placeholder: String,
    max: Number,
    rows: Number,
    multiple: Boolean,
    required: Boolean,
    readonly: Boolean,
    maxCount: { type: Number, default: 1 },
    accept: { type: String, default: 'image/*, video/*, audio/*' },
});
const emit = defineEmits(['update:modelValue']);
const showCalendar = ref(false);
const showLoc = ref(false);
const fileList = ref([]);
const files = computed(function () {
    const ls = typeof prop.modelValue === 'string' ? [prop.modelValue] : prop.modelValue;
    return ls?.map((url) => ({ url }));
});
watch(fileList, function (e) {
    if (prop.multiple) {
        const list = [];
        for (const a of e) list.push(a?.file);
        emit('update:modelValue', list);
    } else {
        emit('update:modelValue', e[0]?.file);
    }
});
function onCalendar(date) {
    emit('update:modelValue', dayjs(date).format('YYYY-MM-DD'));
    showCalendar.value = false;
}
function onLoc(e) {
    emit('update:modelValue', e);
    showLoc.value = false;
}
async function beforeRead(file) {
    return new Promise((resolve, reject) => {
        if (file.type.match('video')) {
            const video = file2blob(file);
            const audio = new Audio(video);
            audio.onloadedmetadata = () => {
                if (audio.duration < 60) return resolve(file);
                app.$notify('视频时长不能超过一分钟');
                reject();
            };
        } else {
            resolve(file);
        }
    });
}
function chooseFile() {
    const el = document.createElement('input');
    el.type = 'file';
    el.onchange = function (e) {
        const file = e.target.files[0];
        emit('update:modelValue', file);
    };
    el.click();
}
function cov(e) {
    if (!e) return '';
    if (typeof e === 'string') return e;
    return file2blob(e);
}
</script>

<style scoped></style>
