import wx from 'jweixin-module';
import md5 from 'md5';
window.goTop = () => window.scrollTo({ top: 0 });
window.cache = function (key, value) {
    if (value) return localStorage.setItem(key, JSON.stringify(value));
    return JSON.parse(localStorage.getItem(key));
};
window.getUserInfo = async (id) => {
    const { data } = await http.sns('userInfo', { id });
    if (!data.avatar) data.avatar = data.headimgurl;
    if (!data.name) data.name = data.nickname;
    return data;
};
window.mine = async () => {
    if (process.env.NODE_ENV === 'development') {
        const { data } = await http.sns('login', { mobile: '18501561989', code: '891218' });
        cache('mine', data);
        return data;
    }
    const mine = cache('mine');
    if (mine && mine.openid) {
        const data = await getUserInfo(mine.openid);
        cache('mine', data);
        return data;
    } else {
        cache('lastUrl', location.href);
        location.href = 'https://svc.ajl.cn/wx/userInfo';
        return new Promise.reject('重新认证');
    }
};
window.wxShares = async function (share) {
    const url = navigator.userAgent.match('iPhone ') ? app.onePath : share.link;
    const data = await http.sns('wxToken', { url });
    if (data.debug) {
        alert(share.link);
        alert(location.href);
        alert(JSON.stringify(data));
    }
    wx.config(data);
    wx.ready(function () {
        share.link = share.link.replace('detail/detail', 'detail/view');
        wx.updateTimelineShareData({ ...share });
        wx.updateAppMessageShareData({ ...share });
    });
};
window.file2blob = function (file) {
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
};
window.getImg = async (url) => {
    const response = await fetch(url, {
        mode: 'cors',
    });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};
window.selectFile = function () {
    return new Promise((resolve) => {
        const el = document.createElement('input');
        el.type = 'file';
        el.onchange = function (e) {
            resolve(file2blob(e.target.files[0]));
        };
        el.click();
    });
};

window.hash = function (str) {
    if (str) return md5(str);
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).slice(1);
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
};
