<template>
    <router-view />
</template>

<script>
export default {
    name: 'SnsView',
};
</script>

<style lang="scss" scoped></style>
