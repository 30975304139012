import { createApp } from 'vue';
createApp({
    name: 'svc',
    render() {
        return null;
    },
    data() {
        return {
            session: [],
            ws: null,
            cid: null,
            uuid: null,
            terminal: 'sns',
            ebs: { msg: new Set() },
        };
    },
    methods: {
        _send(msg) {
            //私有发送消息
            const data = typeof msg === 'string' ? msg : JSON.stringify(msg);
            console.log(data);
            this.ws.send(data);
        },

        _heart() {
            //心跳检测
            this.pingTimeoutId = setTimeout(() => {
                this._send('ping');
                this._pong();
            }, 20000);
        },

        _pong() {
            this.pongTimeoutId = setTimeout(() => {
                console.log('断线重连');
                this.ws.close();
                this.init();
            }, 5000);
        },

        async _post(path, body) {
            let url = `https://svc.ajl.cn/${path}/`;
            const row = await fetch(url, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(body),
            });
            return row.json();
        },
        _getSessionList() {
            //私有 获取会话列表
            if (!this.uuid) return [];
            clearTimeout(this.sessionTimeoutId);
            this.sessionTimeoutId = setTimeout(async () => {
                const obj = { creator: this.uuid };
                if (this.uuid) this.session = await this._post('ws/getSessionListByUid', obj);
            }, 300);
        },
        init() {
            //私有 初始化socket
            this.ws = new WebSocket('wss://svc.ajl.cn');
            this.ws.onmessage = async (e) => {
                clearTimeout(this.pingTimeoutId);
                clearTimeout(this.pongTimeoutId);
                this._heart();
                if (e.data === 'pong') return true;
                const obj = JSON.parse(e.data);
                if (obj.type === 'connect') {
                    console.log('📢', '连接成功' + obj.cid);
                    this.cid = obj.cid;
                    const { openid, avatar, nickname } = await mine();
                    await this.bindUid(openid, avatar, nickname);
                }
                this._getSessionList();
                this.broadcast(obj);
            };
        },

        /**
         *外部调用 登陆绑定
         */
        async bindUid(uid = 0, avatar = null, name = null, only = null) {
            this.uuid = uid;
            let cid = this.cid;
            if (only) cid = 'only'; //注册未登陆过系统的用户
            const obj = { uid, avatar, name, cid, terminal: this.terminal };
            await this._post('ws/bindUid', obj);
            this._getSessionList();
        },

        async unBind() {
            await this._post('ws/unBind', { cid: this.cid, uid: this.uuid });
        },

        /**
         * 外部调用 给用户消息
         */
        async sendToUid(uid = 0, type = 0, data = null) {
            if (!uid) return false;
            const obj = { uid, creator: this.uuid, type, data };
            return this._post('ws/sendtoUid', obj);
        },

        /**
         * 外部调用  消息撤回
         */
        async recallById(id) {
            const obj = { id };
            return this._post('ws/recallById', obj);
        },

        /**
         * 外部调用 获取用户消息
         */
        async getMsgByUid(uid = 0) {
            if (!uid) return false;
            const obj = { uid, creator: this.uuid };
            this._getSessionList();
            return this._post('ws/getMsgByUid', obj);
        },

        /**
         * 外部调用 删除会话
         */
        async delSessionById(id) {
            const obj = { id };
            await this._post('ws/delSessionById', obj);
            this._getSessionList();
            return true;
        },

        /**
         * 爱接无♾广播
         */
        broadcast(e) {
            console.log('📢', e);
            for (const fn of this.ebs.msg ?? []) fn(e);
        },
        on(key, fn) {
            this.ebs[key].add(fn);
        },
        off(key, fn) {
            this.ebs[key].delete(fn);
        },
    },
    created() {
        this.init();
        global.svc = this;
    },
}).mount(document.createElement('i'));
