export default function (app) {
    app.directive('size', (e, c) => {
        let [w, h] = c.arg.toString().split('*');
        if (undefined === h) h = w;
        e.style.width = /%/.test(w) ? w : w + 'px';
        e.style.height = h + 'px';
    });
    app.directive('color', (e, c) => {
        if (c.value) {
            e.style.color = c.value;
        } else {
            e.style.color = c.arg;
        }
    });
    app.directive('bg', (e, c) => {
        e.style.backgroundColor = c.arg;
    });
    app.directive('col', (e, c) => {
        let [start, end] = c.arg.toString().split('-');
        e.style.gridColumn = start + '/' + end;
    });
}
