import wx from 'jweixin-module';
import './dayjs';
import './http';
import './url2blob';
import './global';
import './svc';
import ui from 'vant';
import 'vant/lib/index.css';
import route from './router';
import NumAni from '@/components/num-ani';
import ECard from '@/components/e-card';
import AInput from '@/components/a-input';
import directive from '@/utils/directive';
export default function (app) {
    app.use(ui);
    app.use(route);
    directive(app);
    app.mixin({
        computed: {
            query() {
                return this.$route.query ?? {};
            },
            params() {
                return this.$route.params ?? {};
            },
        },
        mounted() {
            document.addEventListener('onBottom', this.onBottom, false);
        },
        unmounted() {
            document.removeEventListener('onBottom', this.onBottom, false);
        },
    });
    app.config.globalProperties.$serv = () => (location.href = 'https://work.weixin.qq.com/kfid/kfc91f20aae378277a5');
    app.config.globalProperties.$call = (tel) => (location.href = 'tel:' + tel);
    app.config.globalProperties.$open = (url) => (location.href = url);
    app.config.globalProperties.$avatar = function (size = 20) {
        const num = parseInt(Math.random() * 300) + 1;
        const pre = `image/resize,m_fixed,h_${size},w_${size}`;
        return `https://static.ajl.cn/tuoke/avatar/${num}.jpg?x-oss-process=${pre}`;
    };
    app.config.globalProperties.$scan = function () {
        return new Promise((resolve, reject) => {
            wx.scanQRCode({
                needResult: 1,
                success: ({ resultStr }) => resolve(resultStr),
                error: (err) => reject(err),
            });
        });
    };
    app.component('NumAni', NumAni);
    app.component('ECard', ECard);
    app.component('AInput', AInput);
}
