<template>
    <div class="page">
        <van-tabs class="pos-s top-0 pos-top" @click-tab="change">
            <van-tab title="精选海报" />
            <van-tab title="自选海报" />
        </van-tabs>
        <router-view />
    </div>
</template>

<script setup>
function change({ title }) {
    if (title === '精选海报') app.$router.push('/poster/popular');
    if (title === '自选海报') app.$router.push('/poster/custom');
}
</script>
<style lang="scss" scoped>
.page {
    flex: 1;
    display: flex;
    flex-direction: column;
}
</style>
