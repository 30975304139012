import SnsView from '@/views/Sns';
export default {
    name: 'sns',
    component: SnsView,
    meta: { name: '行业社区' },
    children: [
        {
            name: 'site/:id',
            component: () => import('@/views/Sns/site'),
            meta: { name: '微站' },
        },
        {
            name: 'create',
            component: () => import('@/views/Sns/create'),
            meta: { name: '创建圈子' },
        },
        {
            name: 'mine',
            component: () => import('@/views/Sns/mine'),
            meta: { name: '我的圈子' },
        },
        {
            name: 'card/:id',
            component: () => import('@/views/Sns/card'),
            meta: { name: '圈子名片' },
        },
        {
            name: 'dynamic',
            component: () => import('@/views/Sns/dynamic'),
            meta: { name: '我的动态' },
        },
        {
            name: 'dynamic/:id',
            component: () => import('@/views/Sns/dynamic/detail'),
            meta: { name: '动态详情' },
        },
        {
            name: 'publish',
            component: () => import('@/views/Sns/dynamic/publish'),
            meta: { name: '发布动态' },
        },
        {
            name: 'detail/:id',
            component: () => import('@/views/Sns/detail'),
            meta: { name: '圈子详情' },
        },
        {
            name: 'list',
            component: () => import('@/views/Sns/list'),
            meta: { name: '圈子列表' },
        },
    ],
};
